import { Component, Input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-button-primary",
  standalone: true,
  imports: [RouterLink, NgClass],
  template: `
    @if (link === null) {
      <button class="button" [disabled]="disabled" [ngClass]="modification">
        {{ label }}
      </button>
    } @else {
      <button
        class="button"
        [disabled]="disabled"
        [ngClass]="modification"
        [routerLink]="link"
      >
        {{ label }}
      </button>
    }
  `,
  styleUrl: "./button-primary.component.scss",
})
export class ButtonPrimaryComponent {
  @Input() modification: string;
  @Input() link: string = "/menu";
  @Input() label: string;
  @Input() isWhite: boolean = false;
  @Input() disabled: boolean = false;
}
