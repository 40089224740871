import { ResolveFn } from "@angular/router";
import { DataService } from "../services/data.service";
import { inject } from "@angular/core";
import { OtherGame } from "../models/interfaces/other-game.interface";
import { map } from "rxjs";

export const otherGamesResolver: ResolveFn<OtherGame[]> = (
  route,
  state,
  dataService: DataService = inject(DataService),
) => {
  return dataService.getOtherGames();
};
