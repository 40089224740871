import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, Subject } from "rxjs";

interface RestTime {
  minutes: number;
  seconds: number;
  milliseconds: number;
  expiration: number;
}

@Injectable({
  providedIn: "root",
})
export class TimerService {
  private milliseconds$ = new BehaviorSubject<number>(0);
  private isPaused$ = new BehaviorSubject<boolean>(false);
  private reset$ = new Subject<void>();
  private requestId: number | null = null;
  private lastTimestamp = 0;

  public timer$: Observable<RestTime>;

  constructor() {
    this.timer$ = this.milliseconds$.asObservable().pipe(
      map((elapsedMilliseconds) => {
        const totalMilliseconds = elapsedMilliseconds;
        const totalSeconds = totalMilliseconds / 1000;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        const milliseconds = Math.floor(totalMilliseconds % 1000);
        return {
          minutes,
          seconds,
          milliseconds,
          expiration: elapsedMilliseconds,
        };
      }),
    );
  }

  start() {
    this.milliseconds$.next(0);
    this.isPaused$.next(false);
    this.lastTimestamp = performance.now();
    if (this.requestId === null) {
      this.updateTimer();
    }
  }

  pause() {
    this.isPaused$.next(true);
    if (this.requestId !== null) {
      cancelAnimationFrame(this.requestId);
      this.requestId = null;
    }
  }

  resume() {
    this.isPaused$.next(false);
    this.lastTimestamp = performance.now();
    if (this.requestId === null) {
      this.updateTimer();
    }
  }

  reset() {
    this.reset$.next();
    this.milliseconds$.next(0);
    if (this.requestId !== null) {
      cancelAnimationFrame(this.requestId);
      this.requestId = null;
    }
  }

  private updateTimer() {
    this.requestId = requestAnimationFrame((timestamp) => {
      if (this.isPaused$.value) {
        this.requestId = null;
        return;
      }

      const elapsed = timestamp - this.lastTimestamp;
      this.lastTimestamp = timestamp;

      this.milliseconds$.next(this.milliseconds$.value + elapsed);

      if (!this.isPaused$.value) {
        this.updateTimer();
      } else {
        this.requestId = null;
      }
    });
  }
}
