import { BulletItem } from "../app/models/interfaces/bullet-item.interface";
import { EnemyStat } from "../app/models/interfaces/enemy-stat.interface";
import { SpeechBalloon } from "../app/models/interfaces/speech-balloon.interface";
import { LevelLives } from "../app/models/interfaces/level-lives.interface";

export const levelBulletsData: BulletItem[] = [
  {
    id: 1,
    amount: 300,
    isCompleted: false,
    isGotReward: false,
  },
  {
    id: 2,
    amount: 500,
    isCompleted: false,
    isGotReward: false,
  },
  {
    id: 3,
    amount: 1000,
    isCompleted: false,
    isGotReward: false,
  },
  {
    id: 4,
    amount: 1500,
    isCompleted: false,
    isGotReward: false,
  },
  {
    id: 5,
    amount: 2500,
    isCompleted: false,
    isGotReward: false,
  },
];

export const enemyLevelData: Record<number, EnemyStat> = {
  1: {
    id: 1,
    name: "VIPER",
    surname: "QUEEN",
    fullName: "Viper Queen",
    avatar: "/assets/game-resources/viper_queen.png",
  },
  2: {
    id: 2,
    name: "Himalayan",
    surname: "Lioness",
    fullName: "Himalayan Lioness",
    avatar: "/assets/game-resources/himalayan_lioness.png",
  },
  3: {
    id: 3,
    name: "Deadly",
    surname: "Wasp",
    fullName: "Deadly Wasp",
    avatar: "/assets/game-resources/deadly_wasp.png",
  },
  4: {
    id: 4,
    name: "Savage",
    surname: "Ursa",
    fullName: "Savage Ursa",
    avatar: "/assets/game-resources/savage_ursa.png",
  },
  5: {
    id: 5,
    name: "Ginger",
    surname: "Harpy",
    fullName: "Ginger Harpy",
    avatar: "/assets/game-resources/ginger_harpy.png",
  },
};

export const speechBaloonsData: Record<number, SpeechBalloon> = {
  1: {
    puja: `My path to the crown <br> begins here and now!`,
    enemy: `Are you challenging me? Pathetic!`,
  },
  2: {
    puja: `Now we will find out who will become the #1 fighter in India!`,
    enemy: `Be sure, <br> it won't be you.`,
  },
  3: {
    puja: `As my mom said, «Don't fear a bee. <br> Just kill her!»`,
    enemy: `What a silly pun!`,
  },
  4: {
    puja: ` I've been practicing Wushu for 22 years. You're no match for me!`,
    enemy: `Do not poke <br> the bear!`,
  },
  5: {
    puja: `I've been preparing for this battle <br> my whole life!`,
    enemy: `It's a pity that it will become the end of your journey!`,
  },
};

export const levelLivesData: Record<number, LevelLives> = {
  1: {
    puja: 30,
    enemy: 100,
  },
  2: {
    puja: 40,
    enemy: 210,
  },
  3: {
    puja: 30,
    enemy: 200,
  },
  4: {
    puja: 50,
    enemy: 310,
  },
  5: {
    puja: 40,
    enemy: 330,
  },
};

export const finishTitles: Record<number, string> = {
  1: `Puja Tomar defeated all her major opponents`,
  2: `and earned <br> the title`,
  3: "of The Cyclone",
};
