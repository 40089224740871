<div class="modal-stats">
	<div
		class="modal-stats__inner"
		appClickOutside
		[clickEnabled]="animationEnds$()"
    (appClickOutside)="closeModal()"
  >
		<button (click)="closeModal()">
			<img
				class="modal-stats__icon"
				src="/assets/images/big-info-button.png"
				alt="info" />
		</button>
		<div class="modal-stats__list">
			<div class="modal-stats__item">
				<b>Velocity</b> - determines the player's movement speed on the field.
			</div>
			<div class="modal-stats__item">
				<b>Agility</b> - affects the cooldown time of abilities.
			</div>
			<div class="modal-stats__item">
				<b>Strength</b> - affects the speed of the flight of batted balls and
				projectiles.
			</div>
		</div>
	</div>
</div>
