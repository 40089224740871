import { Component, OnInit } from "@angular/core";
import { PreloaderComponent } from "../../components/preloader/preloader.component";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "../../services/data.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-preload-page",
  standalone: true,
  imports: [PreloaderComponent],
  templateUrl: "./preload-page.component.html",
  styleUrl: "./preload-page.component.scss",
})
export class PreloadPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private data: DataService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params["phone"]) {
        this.data.playerPhone$ = Number(params["phone"]);
        this.data.isLocal = false;
        if (params["promoLink"]) {
          this.data.promoLink = params["promoLink"];
        }
        firstValueFrom(this.data.insertPhone());
      } else {
        this.data.isLocal = true;
      }
    });
  }
}
