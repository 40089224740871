<div
  class="modal-container"
  [ngStyle]="{ visibility: modalState.isOpen ? 'visible' : 'hidden' }"
  [ngClass]="{
    open: modalState.animation === 'open',
    close: modalState.animation === 'close',
    'with-overlay': modalState.withOverlay
  }"
>
  <div
    class="modal-component"
    [ngStyle]="{ visibility: modalState.isOpen ? 'visible' : 'hidden' }"
    [ngClass]="{
      open: modalState.animation === 'open',
      close: modalState.animation === 'close'
    }"
  >
    <ng-container
      *ngComponentOutlet="activeModal; inputs: modalState.data"
    ></ng-container>
  </div>
</div>
