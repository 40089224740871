<div class="game-complete">
  <div class="game-complete__title">{{ title }}</div>
  <div class="game-complete__wrapper">
    @switch (gameStatus) {
      @case ("lose") {
        <img
          class="game-complete__image lose"
          src="/assets/images/puja-defeated.png"
          alt="ninza"
        />
      }
      @case ("win") {
        @if (levelId === 5) {
          <img
            class="game-complete__image finish"
            src="/assets/images/puja-wins.png"
            alt="ninza"
          />
        } @else {
          <img
            class="game-complete__image win"
            src="/assets/images/puja-wins.png"
            alt="ninza"
          />
        }
      }
    }
    <div class="game-complete__inner">
      <div class="game-complete__subtitle">Weekly reward</div>
      <app-level-bullets-container
        [bullets]="bullets"
      ></app-level-bullets-container>
      <div class="game-complete__btns">
        @switch (gameStatus) {
          @case ("lose") {
            <app-button-primary
              label="replay the level"
              [link]="null"
              modification="small green"
              (click)="replayLevel()"
            ></app-button-primary>
          }
          @case ("win") {
            @if (levelId < 5) {
              <app-button-primary
                label="next level"
                [link]="null"
                modification="small green"
                (click)="nextLevel()"
              ></app-button-primary>
            }
          }
        }
        @if (gameStatus === "win" && hasReward) {
          @if (levelId < 5) {
            <app-button-primary
              label="claim rewards"
              link="/play/results"
              modification="small grey"
              (click)="claimAwards()"
            ></app-button-primary>
          } @else {
            <app-button-primary
              label="claim rewards"
              link="/play/results"
              modification="small green"
              (click)="claimAwards()"
            ></app-button-primary>
          }
        } @else {
          @if (levelId < 5) {
            <app-button-primary
              label="to menu"
              modification="small grey"
            ></app-button-primary>
          } @else {
            <app-button-primary
              label="to menu"
              modification="small green"
            ></app-button-primary>
          }
        }
      </div>
    </div>
  </div>
</div>
