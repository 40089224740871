import { Component, ErrorHandler, HostListener } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Data, RouterOutlet } from "@angular/router";
import { SmartModalContainerComponent } from "./components/modals/smart-modal-container/smart-modal-container.component";
import {
  animate,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { HttpClientModule } from "@angular/common/http";
import { DataService } from "./services/data.service";
import moment from "moment-timezone";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    SmartModalContainerComponent,
    HttpClientModule,
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  animations: [
    trigger("routeAnimations", [
      transition("* <=> *", [
        style({ position: "relative" }),
        query(
          ":enter, :leave",
          [
            style({
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              width: "100%",
            }),
          ],
          { optional: true },
        ),
        query(":enter", [style({ opacity: 0 })], { optional: true }),
        query(
          ":leave",
          [style({ opacity: 1 }), animate("0.5s", style({ opacity: 0 }))],
          { optional: true },
        ),
        query(":enter", [animate("0.5s", style({ opacity: 1 }))], {
          optional: true,
        }),
      ]),
    ]),
  ],
})
export class AppComponent {
  maxHeight: number;
  maxWidth: number;
  isDesktop: boolean = false;

  constructor(private data: DataService) {
    this.createLocalStorage();
    this.updateSize();
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.updateSize();
  }

  private updateSize(): void {
    this.isDesktop = window.innerWidth > 585;
    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;
  }

  prepareRoute(outlet: RouterOutlet) {
    return {
      value:
        outlet &&
        outlet.activatedRouteData &&
        outlet.activatedRouteData["animation"],
      params: { paddingTop: this.isDesktop ? "25px" : "0px" },
    };
  }

  createLocalStorage() {
    if (!this.data.localUserData) {
      this.data.localUserData = {
        levelCompleted: 0,
        awardReceived: 0,
        score: 0,
        weekNumber: this.getWeekNumberByIST(),
      };
      console.log("local data do not exist");
    } else {
      console.log("local data exist");
    }
  }
  getWeekNumberByIST() {
    // Текущая дата и время в IST
    const nowIST = moment.tz("Asia/Kolkata");

    // Определяем номер недели в году
    return nowIST.isoWeek();
  }
}
