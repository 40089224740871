import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { ModalService } from "../../services/modal.service";
import { NavItem } from "../../models/interfaces/nav-item.interface";
import { ModalType } from "../../models/enums/modal-type.enum";
import { AsyncPipe, NgClass, NgOptimizedImage } from "@angular/common";
import { PlayContainerComponent } from "../../components/play-container/play-container.component";
import { DataService } from "../../services/data.service";
import { Observable } from "rxjs";
import { BulletItem } from "../../models/interfaces/bullet-item.interface";
import { User } from "../../models/interfaces/user.interface";
import { GameService } from "../../services/game.service";
import moment from "moment-timezone";

@Component({
  selector: "app-start-page",
  standalone: true,
  imports: [
    RouterLink,
    NgClass,
    NgOptimizedImage,
    PlayContainerComponent,
    AsyncPipe,
  ],
  templateUrl: "./start-page.component.html",
  styleUrl: "./start-page.component.scss",
})
export class StartPageComponent implements OnInit {
  public currentLevel: number;
  public levelBullets: BulletItem[];
  public user$: Observable<User>;
  constructor(
    private modalService: ModalService,
    private data: DataService,
    private game: GameService,
  ) {
    modalService.closeModal();
    this.currentLevel = this.game.currentLevel;
  }
  public navItems: NavItem[] = [
    {
      id: 1,
      label: "how to play",
      link: "/onboarding",
    },
    {
      id: 2,
      label: "other games",
      link: "/other-games",
    },
  ];

  ngOnInit() {
    const weekNumber = this.getWeekNumberByIST();
    if (weekNumber > this.data?.weekNumber) {
      this.game.score = 0;
      this.data.localUserData = {
        levelCompleted: 0,
        awardReceived: 0,
        weekNumber,
        score: 0,
      };
    } else {
      this.data.weekNumber = weekNumber;
    }
    this.levelBullets = this.data.levelBullets;

    if (!this.data.isLocal && !!this.data.playerPhone) {
      this.user$ = this.data.findUser();
    }
  }

  openModal() {
    this.modalService.openModal(ModalType.PRIZES);
  }

  getWeekNumberByIST() {
    // Текущая дата и время в IST
    const nowIST = moment.tz("Asia/Kolkata");

    // Определяем номер недели в году
    return nowIST.isoWeek();
  }
}
