<div class="game-finished" (click)="nextSlide()" (tap)="nextSlide()">
    <div class="game-finished__title" [innerHTML]="finishTitles[slideId$ | async]"></div>
    @switch(slideId$ | async){
        @case(1){
            <img class="game-finished__image" src="/assets/game-resources/Puja/idle.gif" alt="">
        }
        @case(2){
            <img class="game-finished__image" src="/assets/game-resources/Puja/punch.gif" alt="">
        }
        @case(3){
            <img class="game-finished__image" src="/assets/game-resources/Puja/win-long.gif" alt="">
        }
    }
    <div class="game-finished__text">tap to continue</div>
</div>
