import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
} from "@angular/core";
import { provideRouter, Router, withHashLocation } from "@angular/router";
import { routes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import { API_SERVER } from "./services/data.service";
import { environment } from "../environments/environment";
import { provideHttpClient } from "@angular/common/http";
import { provideEnvironmentNgxMask } from "ngx-mask";
import * as Sentry from "@sentry/angular";

Sentry.init({
  dsn: "https://e5f713d4cf2f4a4a00491281c8a1cb6f@o4507374029963264.ingest.de.sentry.io/4507374032060496",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "ufc.igm.gg", "playforpuja.batery.ai"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideEnvironmentNgxMask(),
    provideRouter(routes, withHashLocation()),
    {
      provide: API_SERVER,
      useValue: environment.apiEndpoint,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
