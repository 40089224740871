import { Component } from "@angular/core";
import { PreloaderComponent } from "../../preloader/preloader.component";
import { ModalBaseComponent } from "../modal-base/modal-base.component";

@Component({
  selector: "app-modal-saving",
  standalone: true,
  imports: [PreloaderComponent],
  templateUrl: "./modal-saving.component.html",
  styleUrl: "./modal-saving.component.scss",
})
export class ModalSavingComponent extends ModalBaseComponent {
  close() {
    super.closeAndNavigate("/menu");
  }
}
