import { Routes } from "@angular/router";
import { StartPageComponent } from "./pages/start-page/start-page.component";
import { GamePageComponent } from "./pages/game-page/game-page.component";
import { OnboardingPageComponent } from "./pages/onboarding-page/onboarding-page.component";
import { OtherGamesPageComponent } from "./pages/other-games-page/other-games-page.component";
import { PreloadPageComponent } from "./pages/preload-page/preload-page.component";
import { GameComponent } from "./components/game/game.component";
import { GameSaveComponent } from "./components/game-save/game-save.component";
import { GameCompleteComponent } from "./components/game-complete/game-complete.component";
import { GameResultsComponent } from "./components/game-results/game-results.component";
import { levelDataResolver } from "./resolvers/enemy-wave-data.resolver";
import { otherGamesResolver } from "./resolvers/other-games.resolver";
import { isHasScoreGuard } from "./guards/is-has-score.guard";
import { isHasStatusGuard } from "./guards/is-has-status.guard";

export const routes: Routes = [
  { path: "", pathMatch: "full", component: PreloadPageComponent },
  {
    path: "menu",
    component: StartPageComponent,
    data: { animation: "menu" },
  },
  {
    path: "play",
    component: GamePageComponent,
    children: [
      { path: "", pathMatch: "prefix", redirectTo: "game" },
      {
        path: "game",
        component: GameComponent,
        resolve: { levelData: levelDataResolver },
      },
      {
        path: "complete",
        component: GameCompleteComponent,
        canActivate: [isHasStatusGuard],
      },
      {
        path: "results",
        component: GameResultsComponent,
        canActivate: [isHasScoreGuard],
      },
      {
        path: "save",
        component: GameSaveComponent,
      },
    ],
    data: { animation: "play" },
  },
  {
    path: "onboarding",
    component: OnboardingPageComponent,
    data: { animation: "onboarding" },
  },
  {
    path: "other-games",
    component: OtherGamesPageComponent,
    resolve: {
      otherGames: otherGamesResolver,
    },
    data: { animation: "other-games" },
  },
];
