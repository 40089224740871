import { Component, Input } from "@angular/core";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { EnemyStat } from "../../models/interfaces/enemy-stat.interface";

@Component({
  selector: "app-level-stats",
  standalone: true,
  imports: [NgOptimizedImage, NgClass],
  templateUrl: "./level-stats.component.html",
  styleUrl: "./level-stats.component.scss",
})
export class LevelStatsComponent {
  @Input() lives: number;
  @Input() name: string = "Puja Tomar";
  @Input() img: string = "/assets/game-resources/avatar_puja.png";
  @Input() position: string;
}
