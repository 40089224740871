import { Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { GameService } from "../../services/game.service";

@Component({
  selector: "app-game-page",
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: "./game-page.component.html",
  styleUrl: "./game-page.component.scss",
})
export class GamePageComponent implements OnInit {
  constructor(private game: GameService) {}
  ngOnInit() {
    this.game.loadAudio();
  }
}
