<div class="game">
  <app-fight-ring
    [playerLives]="restPlayerLives$ | async"
    [enemyLives]="restEnemyLives$ | async"
    [animation$]="animation$"
    [levelId]="levelId"
  ></app-fight-ring>
  <app-control
    [nextItem]="nextItem$ | async"
    (onAttack)="handleAttack($event)"
  ></app-control>
  <div
    class="fight-message"
    [ngClass]="showMessage$ | async"
  ></div>
</div>
