import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ModalState } from "../models/interfaces/modal-state.interface";
import { ModalType } from "../models/enums/modal-type.enum";
import { ModalData } from "../models/types/modal-data.type";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private state$ = new BehaviorSubject<ModalState>({
    type: null,
    isOpen: false,
    animation: null,
    withOverlay: false,
  });
  private closeAnimationSpeed = 500;
  public modalState$: Observable<ModalState> = this.state$.asObservable();

  public openModal(
    type: ModalType,
    withOverlay: boolean = false,
    data?: ModalData,
  ) {
    this.state$.next({
      isOpen: true,
      type,
      animation: "open",
      data,
      withOverlay,
    });
  }

  public closeModal() {
    const state = this.state$.value;
    this.state$.next({ ...state, animation: "close" });
    setTimeout(
      () =>
        this.state$.next({
          isOpen: false,
          type: null,
          animation: "close",
          data: null,
        }),
      this.closeAnimationSpeed,
    );
  }
}
