import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { OtherGame } from '../../models/interfaces/other-game.interface';
import { OtherGameItemComponent } from '../../components/other-game-item/other-game-item.component';
import { ButtonPrimaryComponent } from '../../components/button-primary/button-primary.component';

@Component({
	selector: 'app-other-games-page',
	standalone: true,
	imports: [RouterLink, OtherGameItemComponent, ButtonPrimaryComponent],
	templateUrl: './other-games-page.component.html',
	styleUrl: './other-games-page.component.scss',
})
export class OtherGamesPageComponent {
	otherGames: OtherGame[] = [];
	constructor(private actRoute: ActivatedRoute) {
		this.otherGames = this.actRoute.snapshot.data['otherGames'];
	}
}
