<div class="game-results">
    <div class="game-results__body">
        <div class="game-results__title">CLAIMING THE rewards</div>
        <div class="game-results__subtitle">Results</div>
        <div class="game-results__rows">
            <div class="game-results__row">
                <span>Level</span>
                <span>{{levelId}}</span>
            </div>
            <div class="game-results__row">
                <span>Batery Points</span>
                <span>{{score}}</span>
            </div>
        </div>
        <div class="game-results__info">
            <div class="game-results__image-wrapper">
                <img class="game-results__image" src="/assets/images/attention.svg" alt="">
            </div>
            <div class="game-results__text">If you claim your reward now, you’ll have to replay the whole game to collect points for upcoming levels!</div>
        </div>
    </div>
    @if(levelId < 5){
        <app-button-primary label="go to the next fight" [link]="null" modification="green" (click)="nextLevel()"></app-button-primary>
    }
    <app-button-primary label="collect the reward" [link]="null" modification="grey" (click)="collectReward()"></app-button-primary>
</div>
