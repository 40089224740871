import { Component } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';

@Component({
	selector: 'app-modal-stats',
	standalone: true,
	imports: [ClickOutsideDirective],
	templateUrl: './modal-stats.component.html',
	styleUrl: './modal-stats.component.scss',
})
export class ModalStatsComponent extends ModalBaseComponent {}
