import { Component } from "@angular/core";
import { ModalBaseComponent } from "../modal-base/modal-base.component";

@Component({
  selector: "app-modal-claim-awards",
  standalone: true,
  imports: [],
  templateUrl: "./modal-claim-awards.component.html",
  styleUrl: "./modal-claim-awards.component.scss",
})
export class ModalClaimAwardsComponent extends ModalBaseComponent {}
