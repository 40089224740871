import { Component, Input } from "@angular/core";
import { LevelBulletComponent } from "../level-bullet/level-bullet.component";
import { BulletItem } from "../../models/interfaces/bullet-item.interface";
import { DataService } from "../../services/data.service";
import { User } from "../../models/interfaces/user.interface";

@Component({
  selector: "app-level-bullets-container",
  standalone: true,
  imports: [LevelBulletComponent],
  templateUrl: "./level-bullets-container.component.html",
  styleUrl: "./level-bullets-container.component.scss",
})
export class LevelBulletsContainerComponent {
  @Input() currentLevel: number;
  @Input() user: User;
  @Input() bullets: BulletItem[];
}
