import { Component } from "@angular/core";
import { ButtonPrimaryComponent } from "../button-primary/button-primary.component";
import { Router } from "@angular/router";
import { ModalService } from "../../services/modal.service";
import { ModalType } from "../../models/enums/modal-type.enum";
import { NgOptimizedImage } from "@angular/common";
import { LevelBulletsContainerComponent } from "../level-bullets-container/level-bullets-container.component";
import { GameService } from "../../services/game.service";
import { DataService } from "../../services/data.service";
import { BulletItem } from "../../models/interfaces/bullet-item.interface";

@Component({
  selector: "app-game-complete",
  standalone: true,
  imports: [
    ButtonPrimaryComponent,
    NgOptimizedImage,
    LevelBulletsContainerComponent,
  ],
  templateUrl: "./game-complete.component.html",
  styleUrl: "./game-complete.component.scss",
})
export class GameCompleteComponent {
  gameStatus: "lose" | "win";
  levelId: number;
  bullets: BulletItem[];
  constructor(
    private modal: ModalService,
    private game: GameService,
    private data: DataService,
    private route: Router,
  ) {
    this.bullets = this.data.levelBullets;
    this.gameStatus = game.gameStatus$.value;
    this.levelId = game.currentLevel;
    if (this.levelId === 5 && this.gameStatus === "win") {
      this.modal.openModal(ModalType.GAME_FINISHED);
    }

    this.bullets.forEach(
      (bullet) =>
        (bullet.isCompleted =
          bullet.id <= game.currentLevel && this.gameStatus === "win"),
    );
  }

  get hasReward() {
    return this.bullets?.some(
      (bullet) => !bullet.isGotReward && bullet.isCompleted,
    );
  }
  get title(): string {
    if (this.levelId < 5) {
      return this.gameStatus === "win" ? "PUJA TOMAR  WINS!" : "DEFEATED!";
    } else {
      return this.gameStatus === "win" ? "THE CYCLON WINS!" : "DEFEATED!";
    }
  }
  public openModal() {
    this.modal.openModal(ModalType.BACK_TO_MENU);
  }

  async replayLevel() {
    await this.route.navigate(["/play/game"]);
  }

  async nextLevel() {
    if (this.levelId === 5) return;
    this.game.setNextLevel();
    await this.route.navigate(["/play/game"]);
  }

  async claimAwards() {
    await this.route.navigate(["/play/results"]);
    // if (this.dataService.isLocal) {
    // } else {
    //   this.dataService.insertPhone().subscribe().unsubscribe();
    //   this.modal.openModal(ModalType.SAVING);
    // }
    // this.route.navigate(["/play/game"]);
  }
}
