<div class="stats" [ngClass]="position">
    <div class="avatar">
        <img [ngSrc]="img" width="24" height="24" alt="">
    </div>
    <div class="info">
        <h3 class="name Poppins-Bold">{{name}}</h3>
        <div class="live"  >
            <div class="progress" [style.width.%]="lives">
            </div>
        </div>
    </div>
</div>
