<div class="onboarding">
  <div class="onboarding__body">
    <div class="onboarding__title">How to play</div>
    <swiper-container
      init="false"
      swiperSlider
      [config]="config">
      <swiper-slide *ngFor="let slide of slides">
        <div class="onboarding__slide">
          <div class="onboarding__image-wrapper">
            <img class="onboarding__image" [src]="slide.image" alt="image">
          </div>
          <div class="onboarding__text" [innerHTML]="slide.description"></div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
  <app-button-primary label="back" modification="green"></app-button-primary>
</div>
