<div class="start-page">
  <img class="main-title" src="/assets/images/logo.png" alt="logo">

  <app-play-container [user]="user$ | async" [levelBullets]="levelBullets" [currentLevel]="currentLevel"></app-play-container>

  <nav class="nav">
    @for (item of navItems; let first = $first; track item.id) {
      <button
        class="btn grey"
        [ngClass]="{ red: first }"
        [routerLink]="item.link"
      >
        {{ item.label }}
      </button>
      <!-- button to test the modals -->
      <!--    <button class="btn grey" (click)="openModal()">{{item.label}}</button>-->
    }
  </nav>
</div>
