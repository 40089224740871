import { Component } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';

@Component({
	selector: 'app-modal-rotate',
	standalone: true,
	imports: [],
	templateUrl: './modal-rotate.component.html',
	styleUrl: './modal-rotate.component.scss',
})
export class ModalRotateComponent extends ModalBaseComponent {}
