export interface LevelPreloadData {
  enemyName: string;
  levelId: number;
}

export const levelPreloadMapper: Record<number, LevelPreloadData> = {
  1: {
    enemyName: "BREEZE",
    levelId: 1,
  },
  2: {
    enemyName: "GALE",
    levelId: 2,
  },
  3: {
    enemyName: "HURRICANE",
    levelId: 3,
  },
  4: {
    enemyName: "STORM",
    levelId: 4,
  },
  5: {
    enemyName: "CYCLONE",
    levelId: 5,
  },
};
