<div class="prizes-instruction wrapper-primary">
	<div class="wrapper-primary__inner">
		<div class="prizes-instruction__body">
			<div class="wrapper-primary__title">Rules of prizewinning</div>
			<div class="prizes-instruction__text">
				1. These rules regulate the general procedure for conducting certain
				marketing activities, hereinafter referred to as the "Rules" between
				You, referred to as the “Participant”, and the Batery.ai, referred to as
				the “Organizer”.<br />

				2. An integral part of the Rules is a brief description of a particular
				drawing, published directly in the announcement of the prize-winning as
				part of a marketing campaign.<br />

				3. The prize-winning is not a game of chance.<br />

				4. Some prize-winning may be organized by third parties, marketing
				partners of the Organizer. In such a case, the rules of marketing
				partners also apply to such prize-winning.<br />

				5. By performing the actions provided for in the respective
				prize-winning, the Participant agrees to the Rules.<br />

				6. The dates of the prize-winning are determined in a specific marketing
				action.<br />

				7. The winner of the prize-winning is determined by the Organizer of the
				prize-winning.<br />

				8. Prizes, the number of prizes, the time frame for receiving prizes,
				and other information related to prizes are determined in a specific
				marketing action.<br />

				9. The Participant is allowed to participate in the prize-winning only
				if he has reached the age of majority.<br />

				10. Bonuses in the form of Organizer or its marketing or business
				partner credits may be awarded as prizes. Bonuses are awarded to the
				Participant's account. If the Participant fails to register or violates
				the Rules, then bonuses are not awarded and remain unclaimed. The
				transfer of the right to receive the prize is not allowed.<br />

				11. The Organizer has the right to publish information about the winner
				of the prize-winning on the Website.<br />

				12. The Organizer has the right to change the Rules, so we ask
				participants to check the Rules from time to time for changes. The
				Organizer, as far as possible, will inform the Participants about such
				changes.<br />

				13. It is not allowed to:<br />

				13.1. violate the intellectual and other rights of the Organizer, other
				Participants and third parties;<br />

				13.2. use software that disrupts the operation of the Site or creates an
				advantage for the Participant or is able to change the results of the
				prize-winning;<br />

				13.3. use the data, materials and information posted on the Site to
				perform actions not permitted by the Organizer or the functionality of
				the game;<br />

				13.4. register an account on behalf of another person;<br />

				13.5. register multiple accounts to participate in the prize-winning;<br />

				13.6. perform actions aimed at deceiving the automatic system for
				checking the results of the Draw.<br />

				14. The Organizer is not responsible for:<br />

				14.1. technical failures related to the authorization of Participants,
				including for the correctness, accuracy and reliability of contact and
				other information provided by the Participant;<br />

				14.2. inability to contact the Participant for any reason;<br />

				14.3. actions of third parties whose participation is necessary for
				conducting a marketing campaign;<br />

				14.4. any consequences of the Participant's mistakes in the process of
				participating in the marketing campaign, the Participants' expectations
				regarding the Drawing or prizes.<br />

				15. By participating in this marketing action, the Participant hereby
				voluntarily gives the explicit consent to the Organizer for processing
				and handling its personal data as outlined here:<br />

				15.1. the personal data that may be collected and processed includes,
				but is not limited to, a full name, contact information (address, email,
				phone number), date of birth, and any other information required by the
				Organizer for the purpose of providing services;<br />

				15.2. the purpose of the processing of its personal data is to enable
				the Organizer to carry out its functions and responsibilities, including
				but not limited to providing requested services, managing customer
				relationships, fulfilling obligations related to participating in the
				marketing action;<br />

				15.3. the Participant understands that its personal data may be shared
				with third parties, such as service providers, business or marketing
				partners, or regulatory authorities for the aforementioned purposes or
				as required by law;<br />

				15.4. the Participant understands that he or she has the right to
				access, rectify, or erase its personal data held by the Organizer;<br />

				15.5. the Participant may also withdraw its consent at any time by
				contacting the Organizer.<br />
			</div>
		</div>

		<app-button-primary
			label="back"
			(click)="closeModal()"></app-button-primary>
	</div>
</div>
