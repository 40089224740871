import { Component } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { ButtonPrimaryComponent } from '../../button-primary/button-primary.component';

@Component({
	selector: 'app-modal-prizes-instruction',
	standalone: true,
	imports: [ButtonPrimaryComponent],
	templateUrl: './modal-prizes-instruction.component.html',
	styleUrl: './modal-prizes-instruction.component.scss',
})
export class ModalPrizesInstructionComponent extends ModalBaseComponent {}
