import { Injectable, InjectionToken } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Endpoints } from "../models/enums/endpoints.enum";
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
  throwError,
} from "rxjs";
import { OtherGame } from "../models/interfaces/other-game.interface";
import { FinalScore } from "../models/interfaces/final-score.interface";
import { PossibleRank } from "../models/interfaces/possible-rank.interface";
import { User } from "../models/interfaces/user.interface";
import { PhoneInsert } from "../models/interfaces/phone-insert.interface";
import { PlayItem } from "../models/interfaces/play-item.interface";
import { levelBulletsData } from "../../assets/data";
import { BulletItem } from "../models/interfaces/bullet-item.interface";
import { environment } from "../../environments/environment";
import { GameService } from "./game.service";
import { LocalUserData } from "../models/interfaces/local-user-data.interface";

export const API_SERVER = new InjectionToken<string>("apiServer");

@Injectable({
  providedIn: "root",
})
export class DataService {
  public finalScore: FinalScore;
  public userScore$ = new BehaviorSubject<User>(null);
  public isLocalGameReg$ = new BehaviorSubject<boolean>(true);
  private _levelBullets: BulletItem[] = levelBulletsData;
  private _playerPhone$ = new BehaviorSubject<number>(null);
  private promoLink$ = new BehaviorSubject<string>(null);
  private currentUser$ = new BehaviorSubject<string>(null);
  private api = environment.apiEndpoint;
  constructor(
    private http: HttpClient,
    private game: GameService,
  ) {}

  get isLocal(): boolean {
    return this.isLocalGameReg$.value;
  }

  set isLocal(value: boolean) {
    this.isLocalGameReg$.next(value);
  }

  get levelBullets(): BulletItem[] {
    return this._levelBullets.map((bullet) => {
      if (this.localUserData) {
        bullet.isCompleted = this.localUserData.levelCompleted >= bullet.id;
        bullet.isGotReward = this.localUserData.awardReceived >= bullet.id;
        return bullet;
      } else {
        return bullet;
      }
    });
  }

  get user(): User {
    return this.userScore$.value;
  }

  get user$(): Observable<User> {
    return this.userScore$.asObservable();
  }

  get playerPhone$(): Observable<number> {
    return this._playerPhone$.asObservable();
  }

  get playerPhone(): number {
    return this._playerPhone$.value;
  }

  set playerPhone$(phone: number) {
    this._playerPhone$.next(phone);
  }

  set promoLink(promolink: string) {
    this.promoLink$.next(promolink);
  }

  get promoLink(): string {
    return this.promoLink$.value;
  }

  get weekNumber() {
    return this.localUserData?.weekNumber;
  }

  get localScore() {
    return this.localUserData?.score;
  }

  get completedLevels() {
    return this.localUserData?.levelCompleted;
  }

  get awardReceived() {
    return this.localUserData?.awardReceived;
  }

  set weekNumber(weekNumber) {
    const data = this.localUserData;
    this.localUserData = { ...data, weekNumber };
  }

  set localScore(score: number) {
    const data = this.localUserData;
    if (data.levelCompleted < score) {
      this.localUserData = { ...data, score };
    }
  }

  set completedLevels(levelCompleted: number) {
    const data = this.localUserData;
    if (data.levelCompleted < levelCompleted) {
      this.localUserData = { ...data, levelCompleted };
    }
  }

  set awardReceived(awardReceived: number) {
    const data = this.localUserData;
    if (data.awardReceived < awardReceived) {
      this.localUserData = { ...data, awardReceived };
    }
  }

  get localUserData(): LocalUserData {
    return JSON.parse(localStorage.getItem("localUserData"));
  }

  set localUserData(data: LocalUserData) {
    localStorage.setItem("localUserData", JSON.stringify(data));
  }

  get isOnboarding() {
    return localStorage.getItem("isOnboarding") === "true";
  }

  set isOnboarding(value) {
    localStorage.setItem("isOnboarding", `${value}`);
  }

  get currentLevel(): number {
    return this.game.currentLevel;
  }

  insertPhone(phone: number = this.playerPhone): Observable<User> {
    this.isLocal = false;
    this.playerPhone$ = phone;
    return this.http
      .post<PhoneInsert>(this.api + Endpoints.GAME + "/user", {
        gameId: 3,
        score: this.localScore,
        phone: Number(`91${phone}`),
        promoLink: this.promoLink,
      })
      .pipe(
        map((res: Record<string, any>) => {
          this.isLocal = false;

          return res["data"] as PossibleRank;
        }),
        switchMap(() => this.findUser()),
        catchError((err) => throwError(() => new Error(err))),
      );
  }

  insertScore(): Observable<PossibleRank> {
    return this.http
      .get(
        this.api +
          Endpoints.GAME +
          "/rank?game_id=3&score=" +
          this.finalScore.score,
      )
      .pipe(
        map((res: Record<string, any>) => res["data"] as PossibleRank),
        catchError((err) => throwError(() => new Error(err))),
      );
  }

  getLevelData(): Observable<PlayItem[]> {
    return this.http
      .get(this.api + Endpoints.GAME + `3/levels?level=${this.currentLevel}`)
      .pipe(
        map((res: Record<string, any>) => res["data"] as PlayItem[]),
        catchError((err) => throwError(() => new Error(err))),
      );
  }

  findUser(phone: number = this.playerPhone): Observable<User> {
    return this.http
      .get(this.api + Endpoints.GAME + `/user?phone=91${phone}&game_id=3`)
      .pipe(
        map((res: Record<string, any>) => res["data"] as User),
        tap((user) => {
          this.userScore$.next(user);
          this.localScore = user?.gameLeaderBoard?.score || 0;
          let score = user?.gameLeaderBoard?.score || 0;
          let completedLevels = 0;
          levelBulletsData.forEach((bullet) => {
            if (bullet.amount <= score) {
              score -= bullet.amount;
              completedLevels = bullet.id;
            }
          });
          this.completedLevels = completedLevels;
          this.awardReceived = completedLevels;
        }),
        catchError((err) => throwError(() => new Error(err))),
      );
  }

  getOtherGames(currentGameId: string = "3"): Observable<OtherGame[]> {
    return this.http
      .get(this.api + Endpoints.GAME + "/over-games?game_id=" + currentGameId)
      .pipe(
        map(
          (res: Record<string, any>) =>
            Object.values(res["data"]) as OtherGame[],
        ),
        catchError((err) => throwError(() => new Error(err))),
      );
  }
}
