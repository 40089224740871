<div class="other-games">
  <div class="other-games__body">
    <div class="other-games__title">BATERY GAMES</div>
    <div class="other-games__list">
      @for (game of otherGames; track game.id) {
        <div class="other-games__item">
          @if (game.isSoon) {
              <div class="other-games__image-wrapper">
                  <img class="other-games__image" src="assets/images/question.png" alt="image">
              </div>
              <button class="mini-btn red">Soon</button>
          } @else {
            <div class="other-games__image-wrapper">
              <img
                class="other-games__image"
                [src]="game.img"
                alt="image"
              />
            </div>
            <a [href]="game.url" class="mini-btn grey">Play</a>
          }
        </div>
      }
    </div>
  </div>
  <app-button-primary label="back" modification="green"></app-button-primary>
</div>
