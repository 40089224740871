<div class="control">
  <div class="items-wrapper">
    <div class="high-kick-items" #highKickContainer></div>
    <div class="punch-items" #punchContainer>
    </div>
    <div class="low-kick-items" #lowKickContainer>
    </div>
  </div>
  <div class="btn-wrapper">
    <button
      class="play-btn high-kick"
      (mouseup)="handleMouseUp($event,'high-kick')"
      (mouseout)="handleMouseUp($event,'high-kick')"
      (touchcancel)="handleMouseUp($event,'high-kick')"
      (touchend)="handleMouseUp($event,'high-kick')"
      (mousedown)="handleAttack($event,'high-kick')"
      (touchstart)="handleAttack($event,'high-kick')"
      #highKick
    ></button>
    <button
      class="play-btn punch"
      (mouseup)="handleMouseUp($event,'punch')"
      (mouseout)="handleMouseUp($event,'punch')"
      (touchcancel)="handleMouseUp($event,'punch')"
      (touchend)="handleMouseUp($event,'punch')"
      (mousedown)="handleAttack($event,'punch')"
      (touchstart)="handleAttack($event,'punch')"
      #punch
    ></button>
    <button
      class="play-btn low-kick"
      (mouseup)="handleMouseUp($event,'low-kick')"
      (mouseout)="handleMouseUp($event,'low-kick')"
      (touchcancel)="handleMouseUp($event,'low-kick')"
      (touchend)="handleMouseUp($event,'low-kick')"
      (mousedown)="handleAttack($event,'low-kick')"
      (touchstart)="handleAttack($event,'low-kick')"
      #lowKick
    ></button>
  </div>
</div>
