<div class="comics" (click)="toGame()">
    <div class="comics__title">{{data.enemyName}}</div>
    <div class="comics__subtitle">{{data.levelId}} level</div>
    <div class="comics__body">
        <div class="comics__char _left" #puja>
            <div name="name" class="comics__name">PUJA</div>
            <div name="surname" class="comics__surname">TOMAR</div>
            <div name="text" class="comics__text" [innerHTML]="speechBalloon.puja"></div>
        </div>
        <img class="comics__vs" src="/assets/images/vs.png" alt="vs" #vs>
        <div class="comics__char _right" [ngStyle]="{'background-image': 'url(\'/assets/game-resources/comics/enemy'+ enemyStat.id+ '-phase1&2.gif\''}" #enemy >
            <div name="name" class="comics__name">{{enemyStat.name}}</div>
            <div name="surname" class="comics__surname">{{enemyStat.surname}}</div>
            <div name="text" class="comics__text" [innerHTML]="speechBalloon.enemy"></div>
        </div>
    </div>
    <div class="comics__tap">tap to continue</div>
</div>
