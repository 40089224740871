import { Component } from '@angular/core';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { ModalService } from '../../../services/modal.service';
import { Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { ModalState } from '../../../models/interfaces/modal-state.interface';

@Component({
	selector: 'app-smart-modal-container',
	standalone: true,
	imports: [ModalContainerComponent, AsyncPipe, NgIf],
	templateUrl: './smart-modal-container.component.html',
	styleUrl: './smart-modal-container.component.scss',
})
export class SmartModalContainerComponent {
	public modalState$: Observable<ModalState>;
	constructor(private service: ModalService) {
		this.modalState$ = this.service.modalState$;
	}
}
