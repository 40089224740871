import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ModalBaseComponent } from "../modal-base/modal-base.component";
import { ModalService } from "../../../services/modal.service";
import { DataService } from "../../../services/data.service";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { GameService } from "../../../services/game.service";
import { SpeechBalloon } from "../../../models/interfaces/speech-balloon.interface";
import { NgClass, NgOptimizedImage, NgStyle } from "@angular/common";
import { PlayerStats } from "../../../models/interfaces/player-stats.interface";
import { Router } from "@angular/router";
import { EnemyStat } from "../../../models/interfaces/enemy-stat.interface";
import { enemyLevelData, speechBaloonsData } from "../../../../assets/data";
import { animate, AnimationBuilder, style } from "@angular/animations";
import { LevelPreloadData } from "../../../models/interfaces/level-preload.interface";

@Component({
  selector: "app-modal-game-comics",
  standalone: true,
  imports: [NgOptimizedImage, NgClass, ReactiveFormsModule, NgStyle],
  templateUrl: "./modal-game-comics.component.html",
  styleUrl: "./modal-game-comics.component.scss",
})
export class ModalGameComicsComponent
  extends ModalBaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() data: LevelPreloadData;
  private readonly isOnboarding: boolean = false;
  public speechBalloon: SpeechBalloon;
  public enemyStat: EnemyStat;
  public animationCompleted: boolean = false;
  private frameId = 1;
  constructor(
    protected modalService: ModalService,
    private dataService: DataService,
    private gameService: GameService,
    protected override router: Router,
    private ab: AnimationBuilder,
    private renderer: Renderer2,
  ) {
    super(modalService, router, dataService);
    this.enemyStat = enemyLevelData[gameService.currentLevel];
    this.speechBalloon = speechBaloonsData[gameService.currentLevel];
    this.isOnboarding = this.dataService.isOnboarding;
  }
  @ViewChild("puja") pujaContainer: ElementRef;
  @ViewChild("enemy") enemyContainer: ElementRef;
  @ViewChild("vs") vsContainer: ElementRef;

  ngOnInit() {}

  ngAfterViewInit() {
    this.createFirstSlideAnimation();
  }

  createFirstSlideAnimation() {
    const leftSlideInAnimation = this.ab.build([
      animate("500ms ease-in", style({ transform: "translateX(0)" })),
    ]);
    const opacityAnimation = this.ab.build([
      animate("250ms ease-in", style({ transform: "scale(1)", opacity: 1 })),
    ]);

    const player1 = leftSlideInAnimation.create(
      this.pujaContainer.nativeElement,
    );
    player1.play();
    player1.onDone(() => {
      const nameContainers = this.pujaContainer.nativeElement
        .children as HTMLCollection;
      opacityAnimation.create(nameContainers.namedItem("name")).play();
      const player = opacityAnimation.create(
        nameContainers.namedItem("surname"),
      );
      player.play();
    });
  }

  createSecondSlideAnimation() {
    const rightSlideInAnimation = this.ab.build([
      animate("500ms ease-in", style({ transform: "translateX(0)" })),
    ]);
    const opacityAnimation = this.ab.build([
      animate("250ms ease-in", style({ transform: "scale(1)", opacity: 1 })),
    ]);

    const player = rightSlideInAnimation.create(
      this.enemyContainer.nativeElement,
    );
    const nameContainers = this.enemyContainer.nativeElement
      .children as HTMLCollection;
    player.play();
    player.onDone(() => {
      opacityAnimation.create(nameContainers.namedItem("name")).play();
      opacityAnimation.create(nameContainers.namedItem("surname")).play();
      const vsPlayer = opacityAnimation.create(this.vsContainer.nativeElement);

      vsPlayer.play();
    });
  }
  createThirdSlidePujaAnimation() {
    const opacityInAnimation = this.ab.build([
      animate("250ms ease-in", style({ transform: "scale(1)", opacity: 1 })),
    ]);
    const opacityOutAnimation = this.ab.build([
      animate("250ms ease-in", style({ transform: "scale(0)", opacity: 0 })),
    ]);

    const nameContainers = this.pujaContainer.nativeElement
      .children as HTMLCollection;
    opacityOutAnimation.create(nameContainers.namedItem("name")).play();
    const player = opacityOutAnimation.create(
      nameContainers.namedItem("surname"),
    );
    player.play();
    this.renderer.addClass(this.pujaContainer.nativeElement, "phase2");
    player.onDone(() => {
      const player = opacityInAnimation.create(
        nameContainers.namedItem("text"),
      );
      player.play();
    });
  }
  createThirdSlideEnemyAnimation() {
    const opacityInAnimation = this.ab.build([
      animate("250ms ease-in", style({ transform: "scale(1)", opacity: 1 })),
    ]);
    const opacityOutAnimation = this.ab.build([
      animate("250ms ease-in", style({ transform: "scale(0)", opacity: 0 })),
    ]);

    const nameContainers = this.enemyContainer.nativeElement
      .children as HTMLCollection;
    opacityOutAnimation.create(nameContainers.namedItem("name")).play();
    const player = opacityOutAnimation.create(
      nameContainers.namedItem("surname"),
    );
    player.play();
    player.onDone(() => {
      const player = opacityInAnimation.create(
        nameContainers.namedItem("text"),
      );

      player.play();
      player.onDone(() => {
        this.animationCompleted = true;
      });
    });
  }

  ngOnDestroy() {}

  toGame() {
    this.frameId++;
    switch (this.frameId) {
      case 2: {
        this.createSecondSlideAnimation();
        break;
      }
      case 3: {
        this.createThirdSlidePujaAnimation();
        break;
      }
      case 4: {
        this.createThirdSlideEnemyAnimation();
        break;
      }
      case 5: {
        this.animationCompleted = true;
        break;
      }
    }
    if (this.animationCompleted && this.frameId === 5) {
      this.gameService.comicsOnboardingComplete$.next();
      super.closeModal();
    }
  }
}
