import { Component } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { RouterLink } from '@angular/router';
import {ButtonPrimaryComponent} from "../../button-primary/button-primary.component";

@Component({
	selector: 'app-modal-back-menu',
	standalone: true,
    imports: [RouterLink, ButtonPrimaryComponent],
	templateUrl: './modal-back-menu.component.html',
	styleUrl: './modal-back-menu.component.scss',
})
export class ModalBackMenuComponent extends ModalBaseComponent {}
