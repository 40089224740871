export enum ModalType {
  //modal for stats as velocity, agility and strength
  STATS = "STATS",
  BOMB = "BOMB",
  LOADER = "LOADER",
  LEVEL_PRELOAD = "LEVEL_PRELOAD",
  SAVING = "SAVING",
  //modal for buttons to back to menu
  BACK_TO_MENU = "BACK_TO_MENU",
  //find your score
  RESULTS = "RESULTS",
  CLAIM_AWARDS = "CLAIM_AWARDS",
  //how to get prizes
  PRIZES = "PRIZES",
  //for "comics" with alien
  GAME_COMICS = "GAME_COMICS",
  // rotate your screen
  ROTATE = "ROTATE",
  ALIEN_WINS = "ALIEN_WINS",
  GAME_FINISHED = "GAME_FINISHED",
}
