import { Component, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { OnboardingSlide } from "../../models/interfaces/onboarding-slide.interface";
import { NgForOf, NgOptimizedImage } from "@angular/common";
import { SwiperDirective } from "../../directives/swiper.directive";
import { SwiperOptions } from "swiper/types/swiper-options";
import { RouterLink } from "@angular/router";
import { ButtonPrimaryComponent } from "../../components/button-primary/button-primary.component";

@Component({
  selector: "app-onboarding-page",
  standalone: true,
  imports: [
    NgForOf,
    SwiperDirective,
    NgOptimizedImage,
    RouterLink,
    ButtonPrimaryComponent,
  ],
  templateUrl: "./onboarding-page.component.html",
  styleUrl: "./onboarding-page.component.scss",
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OnboardingPageComponent {
  slides: OnboardingSlide[] = [
    {
      id: 1,
      description: `<strong>Attack:</strong> Quickly press the necessary buttons to attack the opponent. The correct timing of strikes is indicated by colored markers approaching the buttons.`,
      image: "assets/images/how-to-play_slide-1.png",
    },
    {
      id: 2,
      description: `<strong>Defend:</strong> If a long bar follows the marker, press and HOLD the button to enter a blocking state and defend against the opponent's attacks.`,
      image: "assets/images/how-to-play_slide-2.png",
    },
    {
      id: 3,
      description: `<strong>Do not make mistakes:</strong> Press the buttons correctly to perform attacks and reduce opponent’s health bar. For your mistakes, the opponent will perform an attacks, and then your life bar will decrease.`,
      image: "assets/images/how-to-play_slide-3.png",
    },
    {
      id: 4,
      description: `When the enemy's health bar runs out - you will win the level. If your health bar is completely depleted - you will lose and have to replay the level.`,
      image: "assets/images/how-to-play_slide-4.png",
    },
  ];

  public config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 25,
    pagination: {
      clickable: true,
    },
  };
}
