import { Component } from "@angular/core";
import { ButtonPrimaryComponent } from "../button-primary/button-primary.component";
import { NgxMaskDirective } from "ngx-mask";
import { GameService } from "../../services/game.service";
import { levelBulletsData } from "../../../assets/data";
import { Router } from "@angular/router";
import { DataService } from "../../services/data.service";
import { ModalService } from "../../services/modal.service";
import { firstValueFrom } from "rxjs";
import { ModalType } from "../../models/enums/modal-type.enum";

@Component({
  selector: "app-game-results",
  standalone: true,
  imports: [ButtonPrimaryComponent, NgxMaskDirective],
  templateUrl: "./game-results.component.html",
  styleUrl: "./game-results.component.scss",
})
export class GameResultsComponent {
  public levelId: number;
  public score: number = 0;
  constructor(
    private game: GameService,
    private route: Router,
    private data: DataService,
    private modal: ModalService,
  ) {
    this.levelId = this.game.currentLevel;
    if (!!this.data?.user?.gameLeaderBoard?.score) {
      const score = this.game.score - this.data.user.gameLeaderBoard.score;
      this.score = score >= 0 ? score : 0;
    } else {
      this.score = this.game.score;
    }
  }

  nextLevel() {
    this.game.setNextLevel();
    this.route.navigate(["/play/game"]);
  }

  collectReward() {
    if (this.data.playerPhone) {
      this.modal.openModal(ModalType.SAVING, true);
      this.data.awardReceived = this.levelId;
      firstValueFrom(this.data.insertPhone());
      this.game.resetGame();
    } else {
      this.route.navigate(["/play/save"]);
    }
  }
}
