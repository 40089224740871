import { Component, Input } from "@angular/core";
import { NgClass, NgComponentOutlet, NgIf, NgStyle } from "@angular/common";
import { ModalStatsComponent } from "../modal-stats/modal-stats.component";
import { PreloaderComponent } from "../../preloader/preloader.component";
import { ModalBackMenuComponent } from "../modal-back-menu/modal-back-menu.component";
import { ModalResultsComponent } from "../modal-score/modal-results.component";
import { ModalPrizesInstructionComponent } from "../modal-prizes-instruction/modal-prizes-instruction.component";
import { ModalGameComicsComponent } from "../modal-game-comics/modal-game-comics.component";
import { ModalRotateComponent } from "../modal-rotate/modal-rotate.component";
import { ModalBombComponent } from "../modal-bomb/modal-bomb.component";
import { ModalState } from "../../../models/interfaces/modal-state.interface";
import { ModalType } from "../../../models/enums/modal-type.enum";
import { ModalSavingComponent } from "../modal-saving/modal-saving.component";
import { ModalAlienWinsComponent } from "../modal-alien-wins/modal-alien-wins.component";
import { ModalLevelPreloadComponent } from "../modal-level-preload/modal-level-preload.component";
import { ModalGameFinishedComponent } from "../modal-game-finished/modal-game-finished.component";
import { ModalClaimAwardsComponent } from "../modal-claim-awards/modal-claim-awards.component";

@Component({
  selector: "app-modal-container",
  standalone: true,
  imports: [NgComponentOutlet, NgIf, NgClass, NgStyle],
  templateUrl: "./modal-container.component.html",
  styleUrl: "./modal-container.component.scss",
})
export class ModalContainerComponent {
  @Input() modalState: ModalState;
  get activeModal() {
    switch (this.modalState.type) {
      case ModalType.BOMB:
        return ModalBombComponent;
      case ModalType.STATS:
        return ModalStatsComponent;
      case ModalType.BACK_TO_MENU:
        return ModalBackMenuComponent;
      case ModalType.PRIZES:
        return ModalPrizesInstructionComponent;
      case ModalType.RESULTS:
        return ModalResultsComponent;
      case ModalType.GAME_COMICS:
        return ModalGameComicsComponent;
      case ModalType.ROTATE:
        return ModalRotateComponent;
      case ModalType.LOADER:
        return PreloaderComponent;
      case ModalType.LEVEL_PRELOAD:
        return ModalLevelPreloadComponent;
      case ModalType.CLAIM_AWARDS:
        return ModalClaimAwardsComponent;
      case ModalType.SAVING:
        return ModalSavingComponent;
      case ModalType.GAME_FINISHED:
        return ModalGameFinishedComponent;
      case ModalType.ALIEN_WINS:
        return ModalAlienWinsComponent;
      default:
        return null;
    }
  }

  protected readonly ModalType = ModalType;
}
