import { Component, OnDestroy, OnInit } from "@angular/core";
import { ControlComponent } from "../control/control.component";
import { GameService } from "../../services/game.service";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Observable,
  Subject,
  takeUntil,
  takeWhile,
  tap,
} from "rxjs";
import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
} from "@angular/common";
import { ModalService } from "../../services/modal.service";
import { DataService } from "../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FightRingComponent } from "../fight-ring/fight-ring.component";
import { PlayItem } from "../../models/interfaces/play-item.interface";
import { PlayerMovements } from "../../models/enums/player-movements.enum";
import {
  LevelPreloadData,
  levelPreloadMapper,
} from "../../models/interfaces/level-preload.interface";
import { ModalType } from "../../models/enums/modal-type.enum";
import { levelBulletsData, levelLivesData } from "../../../assets/data";
import { LevelLives } from "../../models/interfaces/level-lives.interface";

@Component({
  selector: "app-game",
  standalone: true,
  imports: [
    ControlComponent,
    AsyncPipe,
    NgOptimizedImage,
    NgIf,
    NgForOf,
    FightRingComponent,
    NgClass,
  ],
  templateUrl: "./game.component.html",
  styleUrl: "./game.component.scss",
})
export class GameComponent implements OnInit, OnDestroy {
  public levelId: number = 1;
  public destroy$ = new Subject<void>();
  public nextItem$: Observable<PlayItem>;
  public animation$: Observable<PlayerMovements>;
  public hitAnimationType$ = new BehaviorSubject<PlayerMovements>(null);
  public restPlayerLives$ = new BehaviorSubject<number>(100);
  public restEnemyLives$ = new BehaviorSubject<number>(100);
  public restPlayerLives = 100;
  public restEnemyLives = 100;
  public showMessage$ = new BehaviorSubject<string>(null);
  private isGameComplete = false;
  private isDefence = false;
  private isMissed = false;
  private levelLives: LevelLives;
  constructor(
    private gameService: GameService,
    private modalService: ModalService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
  ) {
    this.gameService.playItems = this.actRoute.snapshot.data["levelData"];
    this.levelId = this.gameService.currentLevel;
    const levelId: number = this.gameService.currentLevel;
    const data: LevelPreloadData = levelPreloadMapper[levelId];
    this.modalService.openModal(ModalType.LEVEL_PRELOAD, true, { data });
  }

  ngOnInit() {
    this.animation$ = this.hitAnimationType$.asObservable().pipe(
      takeUntil(this.destroy$),
      takeWhile(() => !this.isGameComplete),
      filter((val) => val !== null),
      tap((move) => {
        if (move === PlayerMovements.DEFENCE) {
          this.isDefence = true;

          this.showMessage$.next("block");
          setTimeout(() => {
            if (this.showMessage$.value !== "ko") {
              this.isDefence = false;
              this.showMessage$.next(null);
            }
          }, 2000);
          return;
        }
        if (move === PlayerMovements.HIT) {
          if (this.isDefence) return;
          // Вычитаем 1 очко жизни
          this.restPlayerLives = this.restPlayerLives - 1;
          const maxPlayerHealth = this.levelLives.puja;

          // Пересчитываем процент оставшейся жизни
          const playerHealthPercentage = this.calculateHealthPercentage(
            this.restPlayerLives,
            maxPlayerHealth,
          );
          this.restPlayerLives$.next(playerHealthPercentage);
          if (this.isMissed) return;
          this.isMissed = true;
          this.showMessage$.next("missed");
          setTimeout(() => {
            if (this.showMessage$.value !== "ko") {
              this.showMessage$.next(null);
              this.isMissed = false;
            }
          }, 650);
        } else if (
          move === PlayerMovements.PUNCH ||
          move === PlayerMovements.LOW_KICK ||
          move === PlayerMovements.HIGH_KICK
        ) {
          // Вычитаем 1 очко жизни
          this.restEnemyLives = this.restEnemyLives - 1;
          const maxEnemyHealth = this.levelLives.enemy;

          // Пересчитываем процент оставшейся жизни
          const enemyHealthPercentage = this.calculateHealthPercentage(
            this.restEnemyLives,
            maxEnemyHealth,
          );

          // Передаем процент оставшейся жизни через BehaviorSubject
          this.restEnemyLives$.next(enemyHealthPercentage);
        }
      }),
    );
    this.gameService.comicsOnboardingComplete$.subscribe(() => {
      if (this.isGameComplete) return;
      this.initGameSettings().then(() => this.initSubscription());
    });
  }

  ngOnDestroy() {
    this.gameService.destroyGame();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async initGameSettings() {
    await this.gameService.initGame();
    this.levelLives = levelLivesData[this.levelId];
    this.restEnemyLives$.next(100);
    this.restPlayerLives$.next(100);
    this.restPlayerLives = this.levelLives.puja;
    this.restEnemyLives = this.levelLives.enemy;
    this.nextItem$ = this.gameService.nextItemObs$;
    this.showMessage$.next("fight");
    setTimeout(() => this.showMessage$.next(null), 6000);
  }

  public handleAttack(type: PlayerMovements) {
    if (
      this.isGameComplete ||
      this.restPlayerLives$.value <= 0 ||
      this.restEnemyLives$.value <= 0
    )
      return;
    this.hitAnimationType$.next(type);
  }

  private initSubscription() {
    combineLatest([
      this.restPlayerLives$.asObservable(),
      this.restEnemyLives$.asObservable(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([playerLives, enemyLives]) => {
        if (playerLives <= 0) {
          setTimeout(() => this.router.navigate(["/play/complete"]), 2000);
          this.isGameComplete = true;
          this.hitAnimationType$.next(PlayerMovements.LOSE);
          this.gameService.gameStatus$.next("lose");
          this.dataService.localScore = this.gameService.score;
          return;
        } else if (enemyLives <= 0) {
          setTimeout(() => this.router.navigate(["/play/complete"]), 2000);
          this.hitAnimationType$.next(PlayerMovements.WIN);
          this.isGameComplete = true;
          this.showMessage$.next("ko");
          this.gameService.gameStatus$.next("win");
          this.gameService.score =
            this.gameService.score + levelBulletsData[this.levelId - 1].amount;

          this.saveLocalData();
          return;
        }
      });
  }

  private saveLocalData() {
    this.dataService.completedLevels = this.levelId;
    this.dataService.localScore = this.gameService.score;
  }

  private calculateHealthPercentage(currentHealth: number, maxHealth: number) {
    return (currentHealth / maxHealth) * 100;
  }
}
