import { Component, Input } from "@angular/core";
import { BulletItem } from "../../models/interfaces/bullet-item.interface";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-level-bullet",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./level-bullet.component.html",
  styleUrl: "./level-bullet.component.scss",
})
export class LevelBulletComponent {
  @Input() bullet: BulletItem;
}
