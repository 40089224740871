import { Component, Input } from '@angular/core';
import { OtherGame } from '../../models/interfaces/other-game.interface';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { playDfeault, playPressed } from '../../../assets/sprites/sprites';
import { Router } from '@angular/router';

type ItemStatus = 'active' | 'inactive';

@Component({
	selector: 'app-other-game-item',
	standalone: true,
	imports: [NgOptimizedImage, NgClass, NgIf],
	templateUrl: './other-game-item.component.html',
	styleUrl: './other-game-item.component.scss',
})
export class OtherGameItemComponent {
	@Input() item: OtherGame;
	@Input() isActive: boolean = false;
	constructor(private navigator: Router) {}
	// Ссылки на изображения для разных состояний кнопки

	// Переменная, в которой хранится текущий путь к изображению
	buttonImageDefault: boolean = true;

	// Методы для обработки событий нажатия и отпускания кнопки
	onButtonDown(): void {
		window.open(this.item.url, '_blank');
		this.buttonImageDefault = false;
	}

	onButtonUp(): void {
		this.buttonImageDefault = true;
	}

	private colors: Record<ItemStatus, string> = {
		active: '#FF0041',
		inactive: '#363646',
	};

	get filter() {
		return this.isActive ? 'active' : 'inactive';
	}

	get fillColor() {
		return this.isActive ? this.colors.active : this.colors.inactive;
	}
}
