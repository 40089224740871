import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonPrimaryComponent } from '../../button-primary/button-primary.component';
import { ModalBaseComponent } from '../modal-base/modal-base.component';

@Component({
	selector: 'app-modal-alien-wins',
	standalone: true,
	imports: [RouterLink, ButtonPrimaryComponent],
	templateUrl: './modal-alien-wins.component.html',
	styleUrl: './modal-alien-wins.component.scss',
})
export class ModalAlienWinsComponent
	extends ModalBaseComponent
	implements OnInit
{
	ngOnInit() {
		setTimeout(() => {
			super.closeAndNavigate('/play/results');
		}, 3000);
	}
}
