export enum PlayerMovements {
  IDLE = "idle",
  PUNCH = "punch",
  LOW_KICK = "low-kick",
  HIGH_KICK = "high-kick",
  DEFENCE = "defence",
  HIT = "hit",
  WIN = "win",
  LOSE = "lose",
}

export const animationPlayerMapper: Record<PlayerMovements, string> = {
  [PlayerMovements.IDLE]: "idle",
  [PlayerMovements.PUNCH]: "punch",
  [PlayerMovements.LOW_KICK]: "low-kick",
  [PlayerMovements.HIGH_KICK]: "high-kick",
  [PlayerMovements.DEFENCE]: "defence",
  [PlayerMovements.HIT]: "hit",
  [PlayerMovements.WIN]: "win",
  [PlayerMovements.LOSE]: "lose",
};
