import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { PlayItem } from "../models/interfaces/play-item.interface";
import { PushType } from "../models/types/push.type";
import { TimerService } from "./timer.service";
@Injectable({
  providedIn: "root",
})
export class GameService {
  public playItems: PlayItem[];
  public comicsOnboardingComplete$ = new Subject<void>();
  public gameStatus$ = new BehaviorSubject<"lose" | "win">(null);
  private nextItem$ = new BehaviorSubject<PlayItem>(null);
  private currentLevel$ = new BehaviorSubject<number>(1);
  private score$ = new BehaviorSubject<number>(0);
  private music = new Audio();
  constructor(private timerService: TimerService) {}

  get nextItemObs$() {
    return this.nextItem$.asObservable();
  }

  get currentLevel() {
    return this.currentLevel$.value;
  }

  set currentLevel(level: number) {
    this.currentLevel$.next(level);
  }

  set score(score: number) {
    this.score$.next(score);
  }

  get score() {
    return this.score$.value;
  }

  public setNextLevel() {
    this.currentLevel$.next(this.currentLevel$.value + 1);
    this.loadAudio();
  }

  public async initGame() {
    this.initTimer();
    this.initSubscriptions();
    await this.playMusic();
  }

  public loadAudio() {
    this.music.src =
      "/assets/game-resources/music/" + this.currentLevel + "_1.wav";
    this.music.load();
  }

  private initTimer() {
    this.timerService.reset();
    this.timerService.start();
  }

  public destroyGame() {
    this.timerService?.pause();
    this.nextItem$.next(null);
    this.pauseMusic();
  }

  public pauseMusic() {
    if (this.music.currentTime >= 0) {
      this.music?.pause();
    }
  }

  public async playMusic() {
    this.music.preload = "auto";
    await this.music.play();
  }

  public resetGame() {
    this.currentLevel = 1;
    this.score = 0;
    this.gameStatus$.next(null);
  }

  private initSubscriptions() {
    this.timerService.timer$.subscribe((time) => {
      if (this.playItems.length === 0) return;
      const nextItem: PlayItem = this.playItems[0];
      const emitTime = nextItem.interval1Time1 - 310;
      if (time.expiration / 10 >= emitTime) {
        nextItem.pushType = nextItem.pushType.toLowerCase() as PushType;
        this.nextItem$.next(nextItem);
        this.playItems.shift();
      }
    });
  }
}
