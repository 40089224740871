<div class="other-games-item">
	<svg
		class="other-games-item__box"
		width="135"
		height="139"
		viewBox="0 0 135 139"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<g [attr.filter]="'url(#' + filter + ')'">
			<path
				d="M0 15.0037C0 12.3908 2.01177 10.2186 4.61694 10.0184L129.617 0.413623C132.521 0.190499 135 2.48655 135 5.39893V123.996C135 126.609 132.988 128.781 130.383 128.982L5.38306 138.586C2.47923 138.81 0 136.513 0 133.601V15.0037Z"
				[attr.fill]="fillColor" />
		</g>
		<path
			d="M4.69355 11.0154L129.694 1.41068C132.017 1.23219 134 3.06902 134 5.39893V123.996C134 126.087 132.391 127.824 130.306 127.985L5.30644 137.589C2.98339 137.768 1 135.931 1 133.601V15.0037C1 12.9134 2.60942 11.1756 4.69355 11.0154Z"
			stroke="black"
			stroke-width="2" />
		<defs>
			<filter
				id="inactive"
				x="0"
				y="0.398621"
				width="135"
				height="138.203"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB">
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha" />
				<feOffset dy="-4" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.106563 0 0 0 0 0.106563 0 0 0 0 0.14318 0 0 0 1 0" />
				<feBlend
					mode="normal"
					in2="shape"
					result="effect1_innerShadow_1849_475" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha" />
				<feOffset dy="4" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.45 0" />
				<feBlend
					mode="normal"
					in2="effect1_innerShadow_1849_475"
					result="effect2_innerShadow_1849_475" />
			</filter>
			<filter
				id="active"
				x="0"
				y="0.398621"
				width="135"
				height="138.203"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB">
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="BackgroundImageFix"
					result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha" />
				<feOffset dy="-4" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.601904 0 0 0 0 0 0 0 0 0 0.153427 0 0 0 1 0" />
				<feBlend
					mode="normal"
					in2="shape"
					result="effect1_innerShadow_1849_471" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha" />
				<feOffset dy="4" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.45 0" />
				<feBlend
					mode="normal"
					in2="effect1_innerShadow_1849_471"
					result="effect2_innerShadow_1849_471" />
			</filter>
		</defs>
	</svg>
	<ng-template #noImage>
		<img
			class="no-image"
			ngSrc="/assets/images/no-game-image.svg"
			width="117"
			height="80"
			alt=""
			priority />
	</ng-template>

	<div *ngIf="isActive; else noImage" class="other-games-item__image">
		<img [ngSrc]="item.img" width="109" height="74" alt="" priority />
	</div>
	<button
		*ngIf="isActive; else disabledButton"
		class="other-games-item__button"
		(mousedown)="onButtonDown()"
		(mouseup)="onButtonUp()"
		(mouseleave)="onButtonUp()"
		(touchstart)="onButtonDown()"
		(touchend)="onButtonUp()">
    <svg *ngIf="buttonImageDefault; else buttonImagePressed" width="115" height="37" viewBox="0 0 115 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_52_39369)">
        <path d="M0 0H115V23.376C115 25.9861 112.992 28.1571 110.39 28.3608L5.39012 36.5782C2.48381 36.8056 0 34.5086 0 31.5934V0Z" fill="white"/>
      </g>
      <path d="M1 1H114V23.376C114 25.4641 112.394 27.2009 110.312 27.3638L5.3121 35.5812C2.98705 35.7632 1 33.9256 1 31.5934V1Z" stroke="black" stroke-width="3"/>
      <path d="M47.858 14.336C47.858 14.9053 47.7273 15.428 47.466 15.904C47.2047 16.3707 46.8033 16.7487 46.262 17.038C45.7207 17.3273 45.0487 17.472 44.246 17.472H42.762V21H40.368V11.172H44.246C45.03 11.172 45.6927 11.3073 46.234 11.578C46.7753 11.8487 47.1813 12.222 47.452 12.698C47.7227 13.174 47.858 13.72 47.858 14.336ZM44.064 15.568C44.5213 15.568 44.862 15.4607 45.086 15.246C45.31 15.0313 45.422 14.728 45.422 14.336C45.422 13.944 45.31 13.6407 45.086 13.426C44.862 13.2113 44.5213 13.104 44.064 13.104H42.762V15.568H44.064ZM51.4983 19.152H54.6343V21H49.1043V11.172H51.4983V19.152ZM61.8942 19.264H58.2262L57.6382 21H55.1322L58.6882 11.172H61.4602L65.0162 21H62.4822L61.8942 19.264ZM61.2782 17.416L60.0602 13.818L58.8562 17.416H61.2782ZM74.5265 11.172L71.1245 17.752V21H68.7305V17.752L65.3285 11.172H68.0445L69.9485 15.288L71.8385 11.172H74.5265Z" fill="#1A1023"/>
      <defs>
        <filter id="filter0_i_52_39369" x="0" y="0" width="115" height="36.5938" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-4"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.792157 0 0 0 0 0.792157 0 0 0 0 0.792157 0 0 0 1 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_52_39369"/>
        </filter>
      </defs>
    </svg>
    <ng-template #buttonImagePressed>
      <svg width="115" height="37" viewBox="0 0 115 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_ii_52_39371)">
          <path d="M0 0H115V23.376C115 25.9861 112.992 28.1571 110.39 28.3608L5.39012 36.5782C2.48381 36.8056 0 34.5086 0 31.5934V0Z" fill="white"/>
          <path d="M0 0H115V23.376C115 25.9861 112.992 28.1571 110.39 28.3608L5.39012 36.5782C2.48381 36.8056 0 34.5086 0 31.5934V0Z" fill="black" fill-opacity="0.55"/>
        </g>
        <path d="M1 1H114V23.376C114 25.4641 112.394 27.2009 110.312 27.3638L5.3121 35.5812C2.98705 35.7632 1 33.9256 1 31.5934V1Z" stroke="black" stroke-width="3"/>
        <path d="M47.858 14.336C47.858 14.9053 47.7273 15.428 47.466 15.904C47.2047 16.3707 46.8033 16.7487 46.262 17.038C45.7207 17.3273 45.0487 17.472 44.246 17.472H42.762V21H40.368V11.172H44.246C45.03 11.172 45.6927 11.3073 46.234 11.578C46.7753 11.8487 47.1813 12.222 47.452 12.698C47.7227 13.174 47.858 13.72 47.858 14.336ZM44.064 15.568C44.5213 15.568 44.862 15.4607 45.086 15.246C45.31 15.0313 45.422 14.728 45.422 14.336C45.422 13.944 45.31 13.6407 45.086 13.426C44.862 13.2113 44.5213 13.104 44.064 13.104H42.762V15.568H44.064ZM51.4983 19.152H54.6343V21H49.1043V11.172H51.4983V19.152ZM61.8942 19.264H58.2262L57.6382 21H55.1322L58.6882 11.172H61.4602L65.0162 21H62.4822L61.8942 19.264ZM61.2782 17.416L60.0602 13.818L58.8562 17.416H61.2782ZM74.5265 11.172L71.1245 17.752V21H68.7305V17.752L65.3285 11.172H68.0445L69.9485 15.288L71.8385 11.172H74.5265Z" fill="white"/>
        <defs>
          <filter id="filter0_ii_52_39371" x="0" y="0" width="115" height="36.5938" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="-4"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.298799 0 0 0 0 0.298799 0 0 0 0 0.333919 0 0 0 1 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_52_39371"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.45 0"/>
            <feBlend mode="normal" in2="effect1_innerShadow_52_39371" result="effect2_innerShadow_52_39371"/>
          </filter>
        </defs>
      </svg>
    </ng-template>

  </button>
	<ng-template #disabledButton>
		<button class="other-games-item__button">
			<img ngSrc="/assets/images/soon-btn.svg" fill alt="" />
		</button>
	</ng-template>
</div>
