import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { LevelBulletsContainerComponent } from "../level-bullets-container/level-bullets-container.component";
import { ModalService } from "../../services/modal.service";
import { ModalType } from "../../models/enums/modal-type.enum";
import { ButtonPrimaryComponent } from "../button-primary/button-primary.component";
import { BulletItem } from "../../models/interfaces/bullet-item.interface";
import { GameService } from "../../services/game.service";
import { User } from "../../models/interfaces/user.interface";

@Component({
  selector: "app-play-container",
  standalone: true,
  imports: [RouterLink, LevelBulletsContainerComponent, ButtonPrimaryComponent],
  templateUrl: "./play-container.component.html",
  styleUrl: "./play-container.component.scss",
})
export class PlayContainerComponent {
  @Input() user: User;
  @Input() currentLevel: number;
  @Input() levelBullets: BulletItem[];
  constructor(
    private modal: ModalService,
    private router: Router,
    private game: GameService,
  ) {}

  get hasReward(): boolean {
    return this.levelBullets?.some(
      (bullet) => !bullet.isGotReward && bullet.isCompleted,
    );
  }
  async newGame() {
    this.game.resetGame();
    await this.router.navigate(["/play"]);
  }

  openAwardsModal() {
    this.modal.openModal(ModalType.CLAIM_AWARDS);
  }
}
