import { AfterViewInit, Component } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';

@Component({
	selector: 'app-modal-bomb',
	standalone: true,
	imports: [ClickOutsideDirective],
	templateUrl: './modal-bomb.component.html',
	styleUrl: './modal-bomb.component.scss',
})
export class ModalBombComponent
	extends ModalBaseComponent
	implements AfterViewInit
{
	ngAfterViewInit() {
		setTimeout(() => super.closeModal(), 4000);
	}
}
