<div class="fight-ring" *ngIf="enemyData">
    <div class="stats-wrapper">
        <app-level-stats [lives]="playerLives" class="player" position="player"></app-level-stats>
        <app-level-stats [lives]="enemyLives" [name]="enemyName" [img]="enemyData[levelId].avatar" class="enemy" position="enemy"></app-level-stats>
    </div>
    <div class="animation-wrapper">
        <div class="gif-animation player"><img [src]="animationPrefix + '/Puja/'+ (playerAnimationObs | async) + '.gif'"   alt=""></div>
        <div class="gif-animation enemy"><img [src]="animationPrefix + '/enemy'+levelId+'/'+ (enemyAnimationObs | async) + '.gif'"   alt=""></div>
    </div>
    <div class="image-wrapper">
        <img [src]="imgPrefix+levelId +'-bg.gif'" alt="">
    </div>


</div>
