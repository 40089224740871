import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { GameService } from "../services/game.service";

export const isHasStatusGuard: CanActivateFn = (
  route,
  state,
  gameService: GameService = inject(GameService),
  router: Router = inject(Router),
) => {
  if (!!gameService.gameStatus$?.value) {
    return true;
  } else {
    router.navigate(["/menu"]);
    return false;
  }
};
