import { Component } from "@angular/core";
import { ModalBaseComponent } from "../modal-base/modal-base.component";
import { ButtonPrimaryComponent } from "../../button-primary/button-primary.component";
import { ModalService } from "../../../services/modal.service";
import { DataService } from "../../../services/data.service";
import { User } from "../../../models/interfaces/user.interface";
import { Observable } from "rxjs";
import { AsyncPipe, NgIf } from "@angular/common";
import { NgxMaskPipe } from "ngx-mask";
import { Router } from "@angular/router";

@Component({
  selector: "app-modal-results",
  standalone: true,
  imports: [ButtonPrimaryComponent, AsyncPipe, NgIf, NgxMaskPipe],
  templateUrl: "./modal-results.component.html",
  styleUrl: "./modal-results.component.scss",
})
export class ModalResultsComponent extends ModalBaseComponent {
  user$: Observable<User>;
  constructor(
    protected override service: ModalService,
    protected override router: Router,
    private dataService: DataService,
  ) {
    super(service, router, dataService);
  }
}
