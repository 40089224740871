import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
} from '@angular/core';

@Directive({
	selector: '[appClickOutside]',
	standalone: true,
})
export class ClickOutsideDirective {
	@Input() clickEnabled: boolean = false;
	@Output() appClickOutside = new EventEmitter<MouseEvent>();

	constructor(private elementRef: ElementRef) {}

	@HostListener('document:click', ['$event'])
	onClick(event: MouseEvent): void {
		if (!this.clickEnabled) {
			return;
		}
		const clickedInside = this.elementRef.nativeElement.contains(event.target);
		if (!clickedInside) {
			this.appClickOutside.emit(event);
		}
	}
}
