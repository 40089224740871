import { Component, Input, signal } from "@angular/core";
import { ModalAnimationType } from "../../../models/types/modal-animation.type";
import { ModalService } from "../../../services/modal.service";
import { Router } from "@angular/router";
import { DataService } from "../../../services/data.service";
import { ModalType } from "../../../models/enums/modal-type.enum";
import { ModalData } from "../../../models/types/modal-data.type";

@Component({
  selector: "app-modal-base",
  standalone: true,
  template: "",
})
export class ModalBaseComponent {
  @Input() animation: ModalAnimationType;
  animationEnds$ = signal<boolean>(false);
  constructor(
    protected service: ModalService,
    protected router: Router,
    protected server: DataService,
  ) {
    requestAnimationFrame(() => {
      setTimeout(() => {
        this.animationEnds$.set(true);
      });
    });
  }

  openModal(type: ModalType, withOverlay: boolean, data?: ModalData) {
    this.service.openModal(type, withOverlay, data);
  }

  closeModal() {
    this.service.closeModal();
  }

  closeAndNavigate(link: string) {
    this.router.navigate([link]);
    this.closeModal();
  }
}
