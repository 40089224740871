<div
  class="bullet-wrap red"
  [ngClass]="{ green: bullet.isCompleted, grey: bullet.isGotReward }"
>
  <div class="bullet">{{ bullet.id }}</div>
  <div class="score">{{ bullet.amount }}</div>
  <div class="devider"></div>
  @if (bullet.isGotReward) {
    <div class="tick"></div>
  }
</div>
