import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { AsyncPipe, NgIf, NgOptimizedImage } from "@angular/common";
import { LevelStatsComponent } from "../level-stats/level-stats.component";
import { PlayerMovements } from "../../models/enums/player-movements.enum";
import { enemyLevelData } from "../../../assets/data";
import { BehaviorSubject, Observable, takeWhile } from "rxjs";
import { EnemyStat } from "../../models/interfaces/enemy-stat.interface";

@Component({
  selector: "app-fight-ring",
  standalone: true,
  imports: [NgOptimizedImage, LevelStatsComponent, AsyncPipe, NgIf],
  templateUrl: "./fight-ring.component.html",
  styleUrl: "./fight-ring.component.scss",
})
export class FightRingComponent implements OnInit {
  @Input() levelId: number;
  @Input() playerMovement: PlayerMovements;
  @Input() playerLives: number;
  @Input() enemyLives: number;
  @Input() animation$: Observable<PlayerMovements>;
  public playerAnimation$ = new BehaviorSubject(PlayerMovements.IDLE);
  public enemyAnimation$ = new BehaviorSubject(PlayerMovements.IDLE);
  public imgPrefix = "/assets/game-resources/level_";
  public animationPrefix = "/assets/game-resources";
  private isPlayerAnimation = false;
  private isPlayerDefenceAnimation = false;
  private isPlayerFinishAnimation = false;
  private isEnemyAnimation = false;
  public enemyData: Record<number, EnemyStat> = enemyLevelData;

  get playerAnimationValue(): PlayerMovements {
    return this.playerAnimation$.value;
  }

  get enemyAnimationValue(): PlayerMovements {
    return this.enemyAnimation$.value;
  }

  set playerAnimation(value: PlayerMovements) {
    this.playerAnimation$.next(value);
  }

  set enemyAnimation(value: PlayerMovements) {
    this.enemyAnimation$.next(value);
  }

  get playerAnimationObs(): Observable<PlayerMovements> {
    return this.playerAnimation$.asObservable();
  }

  get enemyAnimationObs(): Observable<PlayerMovements> {
    return this.enemyAnimation$.asObservable();
  }
  get enemyName() {
    return this.enemyData[this.levelId].fullName;
  }
  ngOnInit() {
    this.animation$
      .pipe(takeWhile(() => !this.isPlayerFinishAnimation))
      .subscribe((move) => {
        if (
          move === PlayerMovements.DEFENCE ||
          move === PlayerMovements.WIN ||
          move === PlayerMovements.LOSE
        ) {
          this.handleDefenceAndFinishAnimation(move);
        } else {
          if (
            this.isPlayerDefenceAnimation ||
            this.isPlayerFinishAnimation ||
            this.isPlayerAnimation
          )
            return;
          switch (move) {
            case PlayerMovements.HIGH_KICK:
              this.isPlayerAnimation = true;
              this.playerAnimation = PlayerMovements.HIGH_KICK;
              this.enemyAnimation = PlayerMovements.DEFENCE;
              this.resetHitAnimations(700);
              break;
            case PlayerMovements.LOW_KICK:
              this.isPlayerAnimation = true;
              this.playerAnimation = PlayerMovements.LOW_KICK;
              this.enemyAnimation = PlayerMovements.DEFENCE;
              this.resetHitAnimations(500);
              break;
            case PlayerMovements.PUNCH:
              this.isPlayerAnimation = true;
              this.playerAnimation = PlayerMovements.PUNCH;
              this.enemyAnimation = PlayerMovements.DEFENCE;
              this.resetHitAnimations(700);
              break;
            case PlayerMovements.IDLE:
              this.playerAnimation = PlayerMovements.IDLE;
              this.enemyAnimation = PlayerMovements.IDLE;
              break;
            case PlayerMovements.HIT:
              if (this.isEnemyAnimation) return;
              this.isEnemyAnimation = true;
              this.playerAnimation = PlayerMovements.HIT;
              const randomHit = Math.random();
              if (randomHit < 0.5) {
                this.enemyAnimation = PlayerMovements.PUNCH;
              } else {
                this.enemyAnimation = PlayerMovements.LOW_KICK;
              }
              this.resetHitAnimations(500);
              break;
            default:
              break;
          }
        }
      });
  }

  handleDefenceAndFinishAnimation(move: PlayerMovements) {
    switch (move) {
      case PlayerMovements.DEFENCE:
        this.isPlayerDefenceAnimation = true;
        this.playerAnimation = PlayerMovements.DEFENCE;
        const randomHit = Math.random();
        if (randomHit < 0.5) {
          this.enemyAnimation = PlayerMovements.PUNCH;
        } else {
          this.enemyAnimation = PlayerMovements.LOW_KICK;
        }
        this.resetDefenceAnimations(2000);
        break;
      case PlayerMovements.WIN:
        console.log("win");
        this.isPlayerFinishAnimation = true;
        this.playerAnimation = PlayerMovements.WIN;
        this.enemyAnimation = PlayerMovements.LOSE;
        break;
      case PlayerMovements.LOSE:
        console.log("lose");
        this.isPlayerFinishAnimation = true;
        this.playerAnimation = PlayerMovements.LOSE;
        this.enemyAnimation = PlayerMovements.IDLE;
        break;
    }
  }

  private resetHitAnimations(animationTime: number) {
    setTimeout(() => {
      if (
        this.playerAnimationValue === PlayerMovements.WIN ||
        this.playerAnimationValue === PlayerMovements.LOSE
      ) {
        return;
      }
      this.playerAnimation = PlayerMovements.IDLE;
      this.enemyAnimation = PlayerMovements.IDLE;
      this.isPlayerAnimation = false;
      this.isEnemyAnimation = false;
    }, animationTime);
  }

  private resetDefenceAnimations(animationTime: number) {
    setTimeout(() => {
      this.playerAnimation = PlayerMovements.IDLE;
      this.enemyAnimation = PlayerMovements.IDLE;
      this.isPlayerDefenceAnimation = false;
    }, animationTime);
  }
}
