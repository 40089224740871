import { Component } from "@angular/core";
import { ButtonPrimaryComponent } from "../button-primary/button-primary.component";
import { ModalService } from "../../services/modal.service";
import { ModalType } from "../../models/enums/modal-type.enum";
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { DataService } from "../../services/data.service";
import { firstValueFrom } from "rxjs";
import { CustomMask } from "../../models/enums/masks.enum";
import { NgxMaskDirective } from "ngx-mask";
import { GameService } from "../../services/game.service";

@Component({
  selector: "app-game-save",
  standalone: true,
  imports: [ButtonPrimaryComponent, ReactiveFormsModule, NgxMaskDirective],
  templateUrl: "./game-save.component.html",
  styleUrl: "./game-save.component.scss",
})
export class GameSaveComponent {
  public saveForm = this.fb.group({
    phone: [null, [Validators.required, Validators.minLength(10)]],
    policy: [true, [Validators.requiredTrue]],
  });
  constructor(
    private modal: ModalService,
    private fb: FormBuilder,
    private dataService: DataService,
    private game: GameService,
  ) {}

  openLoading() {
    const { phone } = this.saveForm.value;
    this.dataService.awardReceived = this.game.currentLevel;
    firstValueFrom(this.dataService.insertPhone(phone));
    this.game.resetGame();
    this.modal.openModal(ModalType.SAVING, true);
  }

  protected readonly CustomMask = CustomMask;
}
