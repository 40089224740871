import { Component, Input } from "@angular/core";
import { PreloaderComponent } from "../../preloader/preloader.component";
import { ModalBaseComponent } from "../modal-base/modal-base.component";
import { LevelPreloadData } from "../../../models/interfaces/level-preload.interface";
import { ModalType } from "../../../models/enums/modal-type.enum";

@Component({
  selector: "app-modal-level-preload",
  standalone: true,
  imports: [PreloaderComponent],
  templateUrl: "./modal-level-preload.component.html",
  styleUrl: "./modal-level-preload.component.scss",
})
export class ModalLevelPreloadComponent extends ModalBaseComponent {
  @Input() data: LevelPreloadData;
  protected readonly ModalType = ModalType;
}
