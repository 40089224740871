<div class="game-save">
  <div class="game-save__body">
    <div class="game-save__title">CLAIMING THE rewards</div>
    <div class="game-save__subtitle">
      To claim the points, enter your phone number
    </div>
    <form [formGroup]="saveForm" class="game-save__form">
      <div class="game-save__input">
        <input
          prefix="+91 "
          [mask]="CustomMask.PHONE"
          formControlName="phone"
          type="text"
          placeholder="Enter your number"
        />
        <button [disabled]="saveForm.invalid" (click)="openLoading()">
          <svg
            width="36"
            height="21"
            viewBox="0 0 36 21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="24" y="0.467529" width="4" height="4" />
            <rect x="28" y="4.46753" width="4" height="4" />
            <rect x="28" y="12.4675" width="4" height="4" />
            <rect x="24" y="16.4675" width="4" height="4" />
            <rect x="24" y="8.46753" width="4" height="4" />
            <rect x="20" y="8.46753" width="4" height="4" />
            <rect x="16" y="8.46753" width="4" height="4" />
            <rect x="12" y="8.46753" width="4" height="4" />
            <rect x="8" y="8.46753" width="4" height="4" />
            <rect x="4" y="8.46753" width="4" height="4" />
            <rect y="8.46753" width="4" height="4" />
            <rect x="32" y="8.46753" width="4" height="4" />
          </svg>
        </button>
      </div>
      <label class="checkbox" for="checkbox">
        <input
          formControlName="policy"
          type="checkbox"
          name="color"
          value="red"
          id="checkbox"
        />
        <span class="checkmark"></span>
        I agree with Batery.ai Privacy Policy and T&C
      </label>
    </form>
    <div class="game-save__text">
      Using it we will be able to combine the results with other games on the
      Batery platform and contact with you if you win. If you previously earned
      more points, the result will not be overwritten.
    </div>
  </div>
  <div class="game-save__btns">
    <app-button-primary
      modification="green"
      label="Confirm"
      [link]="null"
      [disabled]="saveForm.invalid"
      (click)="openLoading()"
    ></app-button-primary>
    <app-button-primary
      modification="grey"
      label="back"
      link="/play/results"
    ></app-button-primary>
  </div>
</div>
