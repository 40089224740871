import { ResolveFn } from "@angular/router";
import { DataService } from "../services/data.service";
import { inject } from "@angular/core";
import { PlayItem } from "../models/interfaces/play-item.interface";

export const levelDataResolver: ResolveFn<PlayItem[]> = (
  route,
  state,
  dataService: DataService = inject(DataService),
) => {
  return dataService.getLevelData();
};
