import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgStyle,
} from "@angular/common";
import { RestTime } from "../../models/interfaces/rest-time.interface";
import { BehaviorSubject } from "rxjs";
import { PlayItem } from "../../models/interfaces/play-item.interface";
import { PlayerMovements } from "../../models/enums/player-movements.enum";
import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationPlayer,
  style,
} from "@angular/animations";

@Component({
  selector: "app-control",
  standalone: true,
  imports: [NgClass, NgIf, NgStyle, AsyncPipe, NgOptimizedImage, NgForOf],
  templateUrl: "./control.component.html",
  styleUrl: "./control.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class ControlComponent {
  @Input() gameTimer: RestTime;
  @Input() set nextItem(item: PlayItem) {
    if (item === null) return;
    this.createAndAnimateItem(item);
  }
  @Input() leftButtonPressed$ = new BehaviorSubject<boolean>(false);
  @Input() middleButtonPressed$ = new BehaviorSubject<boolean>(false);
  @Input() rightButtonPressed$ = new BehaviorSubject<boolean>(false);
  @Output() onAttack = new EventEmitter<PlayerMovements>();
  @ViewChild("highKickContainer") highKickContainer: ElementRef;
  @ViewChild("punchContainer") punchContainer: ElementRef;
  @ViewChild("lowKickContainer") lowKickContainer: ElementRef;
  @ViewChild("highKick") highKick: ElementRef;
  @ViewChild("punch") punch: ElementRef;
  @ViewChild("lowKick") lowKick: ElementRef;
  private highKickItems: PlayItem[] = [];
  private punchItems: PlayItem[] = [];
  private lowKickItems: PlayItem[] = [];
  private animationTimeShort = 4000;
  private animationTimeLong = 4500;
  constructor(
    private renderer: Renderer2,
    private builder: AnimationBuilder,
  ) {}

  createAndAnimateItem(item: PlayItem) {
    const itemWrapper = this.renderer.createElement("div");
    const img = this.renderer.createElement("div");
    const imageClass: string = this.getImageClass(item.button);
    if (item.pushType === "long") {
      const longImage = this.renderer.createElement("div");
      this.renderer.addClass(longImage, "stripe");
      this.renderer.addClass(longImage, imageClass);
      this.renderer.appendChild(itemWrapper, longImage);
    }
    this.renderer.setAttribute(img, "src", this.getImageSrc(item.button));
    this.renderer.setAttribute(img, "alt", "");
    this.renderer.appendChild(itemWrapper, img);
    this.renderer.addClass(img, "dot");
    this.renderer.addClass(img, imageClass);
    this.renderer.addClass(itemWrapper, "play-item");
    this.renderer.addClass(itemWrapper, item.pushType);

    const container = this.getContainer(item.button);
    if (container) {
      this.renderer.appendChild(container.nativeElement, itemWrapper);
      // Create animation
      const animationLength = item.pushType === "long" ? "100px" : "50px";
      const animationTime =
        item.pushType === "long"
          ? this.animationTimeLong
          : this.animationTimeShort;
      const animation: AnimationFactory = this.builder.build([
        style({ transform: "translate(-50%, -280px)" }),
        animate(
          animationTime + "ms linear",
          style({ transform: "translate(-50%, " + animationLength + ")" }),
        ),
      ]);

      // Play animation
      const player: AnimationPlayer = animation.create(itemWrapper);
      player.onDone(() => {
        if (!itemWrapper.classList.contains("hit")) {
          // Если элемент не был "пойман", засчитываем ошибку
          this.handleMiss();
        } else {
          this.onAttack.emit(PlayerMovements.IDLE);
        }
        // this.renderer.removeChild(container.nativeElement, itemWrapper);
        // Remove from tracking array
        switch (item.button) {
          case 1:
            this.highKickItems = this.highKickItems.filter(
              (i) => i.element !== itemWrapper,
            );
            break;
          case 2:
            this.punchItems = this.punchItems.filter(
              (i) => i.element !== itemWrapper,
            );
            break;
          case 3:
            this.lowKickItems = this.lowKickItems.filter(
              (i) => i.element !== itemWrapper,
            );
            break;
        }
      });
      player.play();
      item.element = itemWrapper;
      switch (item.button) {
        case 1:
          this.highKickItems.push(item);
          break;
        case 2:
          this.punchItems.push(item);
          break;
        case 3:
          this.lowKickItems.push(item);
          break;
      }
    }
  }

  getImageSrc(buttonType: number): string {
    switch (buttonType) {
      case 1:
        return "/assets/game-resources/controls/high-kick_item.svg";
      case 2:
        return "/assets/game-resources/controls/punch_item.svg";
      case 3:
        return "/assets/game-resources/controls/low-kick_item.svg";
      default:
        return "";
    }
  }

  getImageClass(buttonType: number): string {
    switch (buttonType) {
      case 1:
        return "high-kick";
      case 2:
        return "punch";
      case 3:
        return "low-kick";
      default:
        return "";
    }
  }

  getContainer(buttonType: number): ElementRef {
    switch (buttonType) {
      case 1:
        return this.highKickContainer;
      case 2:
        return this.punchContainer;
      case 3:
        return this.lowKickContainer;
      default:
        return null;
    }
  }

  handleMouseUp(event: MouseEvent | TouchEvent, attackType: string) {
    this.renderer.removeClass(event.target, "shield");
    let items: PlayItem[];
    switch (attackType) {
      case "high-kick":
        items = this.highKickItems;
        break;
      case "punch":
        items = this.punchItems;
        break;
      case "low-kick":
        items = this.lowKickItems;
        break;
      default:
        return;
    }
    const hitItem = items.find((item) =>
      this.isItemBeneathButton(item.element, event.target as HTMLElement),
    );
    if (hitItem) {
      this.onAttack.emit(PlayerMovements.IDLE);
    } else if (hitItem?.pushType === "long") {
      this.onAttack.emit(PlayerMovements.HIT);
    }
  }

  handleAttack(event: MouseEvent | TouchEvent, attackType: string) {
    event.preventDefault();
    this.renderer.addClass(event.target, "press");
    let items: PlayItem[];
    switch (attackType) {
      case "high-kick":
        items = this.highKickItems;
        break;
      case "punch":
        items = this.punchItems;
        break;
      case "low-kick":
        items = this.lowKickItems;
        break;
      default:
        return;
    }

    const hitItem = items.find((item) => {
      if (
        this.isItemUnderButton(item.element, event.target as HTMLElement) &&
        !item.element.classList.contains("hit")
      ) {
        this.changeItemImage(item);
        return true;
      }
      return false;
    });
    if (hitItem) {
      if (hitItem.pushType === "long") {
        this.renderer.addClass(event.target, "shield");
        this.onAttack.emit(PlayerMovements.DEFENCE);
      } else {
        this.onAttack.emit(attackType as PlayerMovements);
        this.renderer.addClass(event.target, "true");
      }
    } else {
      this.renderer.addClass(event.target, "false");
      this.onAttack.emit(PlayerMovements.HIT);
    }
    setTimeout(() => {
      this.renderer.removeClass(event.target, "press");
      this.renderer.removeClass(event.target, "false");
      this.renderer.removeClass(event.target, "true");
    }, 350);
  }

  isItemUnderButton(item: HTMLElement, btn: HTMLElement): boolean {
    const img = item.querySelector(".dot");
    const rect = img.getBoundingClientRect();
    const buttonRect = btn.getBoundingClientRect();
    return (
      rect.bottom >= buttonRect.top + 20 && rect.bottom <= buttonRect.bottom
    );
  }

  isItemBeneathButton(item: HTMLElement, btn: HTMLElement): boolean {
    const img = item.querySelector(".stripe");
    if (!img) return false;
    const rect = img.getBoundingClientRect();
    const buttonRect = btn.getBoundingClientRect();
    return rect.bottom >= buttonRect.top && rect.top <= buttonRect.bottom;
  }

  changeItemImage(item: PlayItem) {
    const img = item.element.querySelector(".dot");

    this.renderer.addClass(item.element, "hit");
    this.renderer.addClass(img, "hit");

    if (item.pushType === "long") {
      const img = item.element.querySelector(".stripe");
      this.renderer.addClass(img, "hit");
    }
  }

  handleMiss() {
    this.onAttack.emit(PlayerMovements.HIT);
  }
}
