import { Component } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AsyncPipe } from "@angular/common";
import { finishTitles } from "../../../../assets/data";
import { ModalService } from "../../../services/modal.service";

@Component({
  selector: "app-modal-game-finished",
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: "./modal-game-finished.component.html",
  styleUrl: "./modal-game-finished.component.scss",
})
export class ModalGameFinishedComponent {
  public slideId$ = new BehaviorSubject<number>(1);
  private isAnimation = false;
  constructor(private modal: ModalService) {}
  nextSlide() {
    console.log("nextSlide");
    if (this.isAnimation) return;
    if (this.slideId$.value === 3) {
      return this.modal.closeModal();
    }
    this.slideId$.next(this.slideId$.value + 1);
    setTimeout(() => {
      this.isAnimation = false;
    }, 1000);
  }

  protected readonly finishTitles = finishTitles;
}
